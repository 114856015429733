<template>
	<div class="drive_route">
		<van-nav-bar
		class="nav_bar_color"
		title="试驾路线设置"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11" 
		:border="false"
		placeholder
		>
	</van-nav-bar>
			<!-- tab菜单 -->
				<MenuRouter :list="menuTabList" :listChecked="menuTabChecked" v-on:edit="handMenuTab"></MenuRouter>
				
				<!-- 路线 -->
				<div v-if="menuTabChecked == 'route'" class="route">
					<div v-for="v,index in routelist" :key="index" class="route_list">
						<div class="route_list_title">
							{{v.title}}
						</div>
						<img class="route_list_runway" :src="serverUrl(v.route_image)" alt="">
						
						<img v-if="index != 0" @click="delConfirm(v)" class="error" src="@/assets/drivroute/icon_error.png" mode="">
						<img v-if="v.show == 0" @click="isshow(v)" class="xianshi" src="@/assets/drivroute/icon_yongcnag.png" mode="">
						<img v-if="v.show == 1" @click="isshow(v)" class="xianshi" src="@/assets/drivroute/icon_xianshi.png" mode="">
						<div v-if="index != 0" @click="sortlitre(index)" class="shang">
							<img src="@/assets/drivroute/icon_shang.png" mode="">
						</div>
						<div v-if="(index+1) != routelist.length" @click="sortfall(index)" class="xia">
							<img src="@/assets/drivroute/icon_xia.png" mode="">
						</div>

					</div>
				</div>
				
				<!-- 体验点 -->
				<div v-if="menuTabChecked == 'points'" class="points">
					<!-- 车型选择 -->
					<div style="height: 40px;"></div>
					<CarModel v-on:edit="handCarModel"></CarModel>
					<div class="points_list" v-for="v,index in pointlist.all_experience_point" :key="index">
						<div class="points_list_title">
							{{v.node_name}}
						</div>
						<div class="points_list_block">
							<div @click="Selected(v2.id)" v-for="v2,index2 in v.res" :key="index2" class="points_list_block_experience">
								<img v-if="point_ids.indexOf(v2.id) == -1" src="@/assets/drivroute/icon_weixz.png" mode="">
								<img v-else src="@/assets/drivroute/icon_xuanzhong.png" mode="">
								<span>{{v2.title}}</span>
							</div>
						</div>
					</div>
				</div>
		
		
		<!-- 底部 -->
		<div class="user_footer">
			<div v-if="menuTabChecked != 'points'" class="user_footer_add" @click="open">
				<div class="user_footer_title">
					上传路线图
				</div>
				<div class="user_footer_concat">
					建议尺寸：宽700px，高525px
				</div>
			</div>
			
			<div v-if="menuTabChecked == 'points'" class="user_footer_add" @click="savepoint">
				<div class="user_save">
					保存
				</div>
			</div>
		</div>
		
			<!-- 添加弹框 -->
		<van-popup v-model="show" :style="{'background-color':'#575757'}"  ref="popup" position="bottom">
				<div class="popup_top">
					<input type="text" placeholder-class="placeholderinput" v-model="scsjtitle" class="input_titile" placeholder="点击输入路线标题" />
					<van-uploader :before-read="before" :after-read="uploadavatar">
						<div class="popup_upload">
							<span v-if="imgurl == null">点击添加路线图</span>
							<img v-if="imgurl != null" :src="serverUrl(imgurl)" mode="">
						</div>
					</van-uploader>
					<div class="popup_prompt">
						建议尺寸：宽700px，高525px
					</div>
					<div class="popup_btn" @click="save">
						保存
					</div>
				</div>	
		</van-popup>
		
	</div>
</template>

<script>	
import MenuRouter from '@/components/menutab-router.vue'
import CarModel from '@/components/carmodel.vue'
import { Dialog } from 'vant';
	export default {
		components: {
			MenuRouter,
			CarModel
		},
		data() {
			return {
				navBarHeight: 100,
				show:false,
				routelist:[],
				pointlist:[],
				pointinfo:[],
				scrollTop: 0,
				
				// 添加标题图片
				scsjtitle:'',
				imgurl:null,
				
				// 删除数据
				delid:'',
				
				// tab选项
				menuTabList: [{
					name: '路线',
					value: 'route'
				}, {
					name: '体验点',
					value: 'points'
				}],
				menuTabChecked: 'route',
				
				carmodelId: 0,
				point_ids:[],
			};
		},
		mounted(){
			this.getstorerouteinfo()
		},
		methods:{
			// 判断类型
			before(file,detail){
				console.log(file)
				if(file.type.slice(0,5) == 'image'){
					return true
				}else{
					this.$toast.fail('请上传图片文件')
				}
			},
			// 上传头像
			async uploadavatar(e){
				// console.log('11111',e.file)
				const forms  = new FormData();//通过formdata上传
				forms.append('file', e.file)
				console.log(forms.get('file'))
				const {data:res} = await this.$http.post('/api/alioss/upload',forms)
				if(res.code == 1){
					this.$toast.success('上传成功')
					this.imgurl = res.data.res.url
				}else{
					this.$toast.fail(res.msg)
				}
			},
			// 修改体验点
			async savepoint(){
				const ids_list = this.point_ids.join()
				if(this.point_ids.length < 12) return this.$toast.fail('至少选择12项体验店')
				const {data:res} = await this.$http.post('/api/scsj/edit_store_experience_point',{car_model_id:this.carmodelId,scsj_experience_point_ids:ids_list})
				if(res.code == 1){
					this.$toast.success('保存成功')
				}else{
					return this.$toast.fail(res.msg)
				}
			},
			
			// 是否选中
			Selected(Id){
				const that_id = this.point_ids.indexOf(Id)
				if(that_id != -1){
					this.point_ids.splice(that_id,1)
				}else{
					this.point_ids.push(Id)
				}
			},
			// 切换车型
			handCarModel(e) {
				this.carmodelId = e
				this.pointinfo.forEach(item => {
					if(item.scsj_car_model_id == this.carmodelId){
						this.pointlist = item
						this.point_ids = item.checked_point_ids.split(',')
						this.point_ids = this.point_ids.map(item => {
							return parseInt(item)
						})
					}
				})
			},
		
			// 删除弹框确定
			 delConfirm(v){
				Dialog.confirm({
						title: '删除',
						message: '确定删除该路线吗',
						className:'del_dialog'
					})
						.then(async () => {
							const {data:res} = await this.$http.post('/api/scsj/del_store_route_1_5_1',{route_id:v.id})
								if(res.code == 1){
									this.$toast.success('删除成功')
									this.getstorerouteinfo()
								}else{
									this.$toast.fail(res.msg)
								}
						})
						.catch(() => {
						});
			},
			// 添加路线
			async save(){
				const {data:res} = await this.$http.post('/api/scsj/add_store_route_1_5_1',{route_image:this.imgurl,title:this.scsjtitle})
				if(res.code == 1){
					this.$toast.success('上传成功')
					this.scsjtitle = ''
					this.imgurl = null
					this.show = false
					this.getstorerouteinfo()
				}else{
					this.$toast.fail(res.msg)
				}
			},
			// 打开添加弹框
			open(){
				if(this.routelist.length >= 5){
					return this.$toast.success('路线图长度最大为5个','error')
				}
				this.show = true
			},
			
			
			// 是否隐藏
			async isshow(v){
				const {data:res} = await this.$http.post('/api/scsj/show_hide_store_route',{route_id:v.id,show:v.show == 1?0:1})
				if(res.code == 1){
					v.show == 0?v.show = 1:v.show = 0
				}
			},
			// 排序
			async sortlist(){
				const id_ids = this.routelist.map(item => {
					return item.id
				})
				const id_idss = id_ids.join(',')
				const {data:res} = await this.$http.post('/api/scsj/order_store_route',{route_ids:id_idss})
				if(res.code == 1){
					this.$toast.success('排序成功')
					this.getstorerouteinfo()
				}
			},
			
			// 上升
			sortlitre(index){
				// const res = index - 1
				[this.routelist[index],this.routelist[index-1]] = [this.routelist[index-1],this.routelist[index]]
				this.sortlist()
			},
			
			// 下降
			sortfall(index){
				[this.routelist[index],this.routelist[index+1]] = [this.routelist[index+1],this.routelist[index]]
				this.sortlist()
			},
			// 获取路线数据
			async getstorerouteinfo(){
				const {data:res} = await this.$http.post('/api/scsj/get_store_route_1_5_1')
				if(res.code == 1){
					this.routelist = res.data.res
				}
			},
			
			// 获取体验店数据
			async getstorepointinfo(){
				const {data:res} = await this.$http.post('/api/scsj/get_store_experience_point')
				if(res.code == 1){
					this.pointinfo = res.data.res
					this.pointlist = this.pointinfo[0]
					this.carmodelId = this.pointinfo[0].scsj_car_model_id
					this.point_ids = this.pointlist.checked_point_ids.split(',')
					this.point_ids = this.point_ids.map(item => {
						return parseInt(item)
					})
				}
			},
			
			// 点击tab菜单
			handMenuTab(e) {
				this.menuTabChecked = e
				if(e == 'route'){
					this.getstorerouteinfo()
				}else{
					this.getstorepointinfo()
				}
				
			},
		}
	}
</script>

<style lang="scss">
.drive_route{
	padding: 100px 0 220px;
	position: relative;
}
.route{
	.route_list{
		margin-top: 20px;
		padding: 40px 20px;
		display: flex;
		position: relative;
		background: #373C46;
		flex-direction: column;
		align-items: center;
		.route_list_title{
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			font-weight: normal;
			color: #F4F4F4;
			line-height: 32px;
		}
		.route_list_runway{
			margin-top: 20px;
			width: 268px;
			height: 200px;
		}
		.error{
			position: absolute;
			top: 24px;
			left: 32px;
			height: 40px;
			width: 40px;
		}
		.xianshi{
			position: absolute;
			bottom: 0;
			left: 32px;
			height: 88px;
			width: 108px;
		}
		.shang{
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			top: 24px;
			right: 32px;
			width: 80px;
			height: 40px;
			background: #FA0037;
			border-radius: 8px;
			img{
				width: 88px;
				height: 88px;
			}
		}
		.xia{
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			bottom: 24px;
			right: 32px;
			width: 80px;
			height: 40px;
			background: #FA0037;
			border-radius: 8px;
			img{
				width: 88px;
				height: 88px;
			}
		}
		
	}
}

.user_footer{
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 182px;
	background: #000000;
	text-align: center;
	.user_footer_add{
		width: 670px;
		background: #FA0037;
		text-align: center;
		margin: 30px auto 0;
		line-height: 60px;
		font-size: 36px;
		font-family: HYQiHeiY3-65;
		font-weight: normal;
		color: #FFFFFF;
		.user_save{
			height: 100px;
			text-align: center;
			font-size: 36px;
			font-family: HYQiHeiY3-55;
			font-weight: normal;
			color: #FFFFFF;
			line-height: 100px;
		}
		.user_footer_concat{
			padding-bottom: 20px;
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			font-weight: normal;
			color: #F4F4F4;
			line-height: 32px;
			
		}
	}
}

.popup_top{
	width: 100%;
	height: 826px;
	padding: 60px 0;
	text-align: center;
	// background: #575757;
	border-radius: 46px 46px 0px 0px;
	.input_titile{
		border: 1px solid #FFFFFF;
		width: 670px;
		height: 92px;
		font-size: 36px;
		font-family: HYQiHeiY3-45 !important;
		font-weight: normal;
		color: #FFFFFF;
		background-color: rgba(0, 0, 0, 0);
		line-height: 92px;
		text-align: center;
	}
	
	.popup_upload{
		width: 670px;
		height: 360px;
		box-sizing: border-box;
		background: #6F6F6F;
		margin: 40px auto;
		display: flex;
		justify-content: center;
		align-items: center;
		span{
			font-size: 36px;
			font-family: HYQiHeiY3-45;
			font-weight: normal;
			color: #FFFFFF;
			line-height: 40px;
		}
		img{
			max-width: 670px;
			max-height: 360px;
		}
	}
	.popup_prompt{
		font-size: 28px;
		text-align: center;
		margin: 16px auto;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		color: #FFFFFF;
		line-height: 32px;
	}
	.popup_btn{
		width: 670px;
		height: 92px;
		background: #FA0037;
		text-align: center;
		line-height: 92px;
		margin: 80px auto 0;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #FFFFFF;
	}
}

.points_list{
	padding: 40px;
	.points_list_title{
		font-size: 32px;
		font-family: Regular;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 36px;
	}
	.points_list_block{
		margin-top: 30px;
		padding: 40px 30px;
		background: #222222;
		.points_list_block_experience{
			padding: 20px 0;
			display: flex;
			align-items: center;
			img{
				width: 50px;
				height: 50px;
			}
			span{
				width: 500px;
				font-size: 32px;
				margin-left: 15px;
				font-family: HYQiHeiY3-45;
				font-weight: 300;
				color: #FFFFFF;
				line-height: 36px;
			}
		}
	}
}

.del_dialog{
	font-family: HYQiHeiY3-45;
}
</style>

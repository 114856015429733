<template>
	<div class="menus" :style="{'position': 'fixed','top':navBarHeight + 'px'}">
		<div class="menu" v-for="item in list" :key="item.value" @click="menuEdit(item)">
			<span :class="[item.value == listChecked ? 'menu--active' : '']">{{item.name}}</span>
		</div>
	</div>
</template>

<script>

export default {
	name: 'MenutabRouter',
	props: {
		list: Array,
		listChecked: String
	},
	data() {
		return {
			navBarHeight: 41,
		};
	},
	methods: {
		// 菜单切换
		menuEdit(e) {
			this.$emit('edit', e.value)
		}
	}
}
</script>

<style lang="scss" scoped>
.menus{
	width: 100%;
	display: flex;
	z-index: 999;
	border-bottom: 1px solid rgba(216, 216, 216, 0.1);
	background-color: rgba(25, 25, 25, 1);
}
.menu{
	width: 50%;
	line-height: 70px;
	font-size: 36px;
	font-family: HYQiHeiY3-45;
	color: #6F6F6F;
	text-align: center;
}
.menu--active{
	position: relative;
	color: #fff;
}
.menu--active::after{
	content: '';
	position: absolute;
	// left: 30px;
	left: 0;
	bottom: -17px;
	height: 1px;
	width: 90px;
	background: #fff;
}

</style>